import '@/app/commons';
import Vue from 'vue';
import { onMounted, ref } from '@vue/composition-api';
// import { Debug } from '@/shared/utils/helpers';
import vuetify from '../app/plugins/vuetify';
import Header from '@/app/layouts/header';
import { data } from '@/app/connectors/observable';
import Footer from '@/app/layouts/footer';
import Carga from '@/app/layouts/carga';

const carga = Carga;

const header = Header;
const footer = Footer;
const Debug = console;
const Index = Vue.extend({
  setup() {
    onMounted(() => {
      Debug.log('Index page run');
      data.listo = true;
    });
    const formulario = ref(false);
    const toggForm = () => {
      formulario.value = !formulario.value;
    };
    return {
      formulario,
      toggForm,
    };
  },
});
export default new Index({
  el: '#index',
  vuetify,
  delimiters: ['[{', '}]'],
});
